import $ from 'jquery';
import 'jquery-validation';
import Prism from 'prismjs';
import scrollCue from './scrollCue';

$(document)
    .ready(() => {
        Prism.highlightAll();

        // Hide Loader
        $('#preloader')
            .hide();

        // Animation Js
        // console.log(scrollCue);
        scrollCue.init();

        $('.color_mode')
            .change(function () {
                if ($(this)
                    .is(':checked')) {
                    $('html')
                        .attr('data-bs-theme', 'dark');
                    $('.light', '.color-mode-wrap')
                        .addClass('d-none');
                    $('.dark', '.color-mode-wrap')
                        .removeClass('d-none');
                } else {
                    $('html')
                        .attr('data-bs-theme', 'light');
                    $('.dark', '.color-mode-wrap')
                        .addClass('d-none');
                    $('.light', '.color-mode-wrap')
                        .removeClass('d-none');
                }

                const mode = $(this)
                        .is(':checked'),
                    date = new Date();
                date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
                const expires = `; expires=${date.toUTCString()}`;
                document.cookie = `dark-mode=${mode || ''}${expires}; path=/`;
            })
            .trigger('change');

        $('.opening-icon .icon')
            .click(function () {
                $('#job_id', '#job_form').val($(this).data('job_id'));
                window.scrollTo({
                    top: $('#job_form')
                        .offset().top - 150,
                    behavior: 'smooth',
                });
            });

        $('.scroll-contact-box').click(() => {
            $('[type=text]:first', '.massage-warp').focus();
            window.scrollTo({
                top: $('.massage-warp').offset().top - 50,
                left: 0,
                behavior: 'smooth',
            });
        });

        /* Handle Contact Request */
        $('#frmContact')
            .validate({
                errorClass: 'text-danger',
                submitHandler(form) {
                    // Serialize the form data
                    // const formData = new FormData(form);
                    // console.log(formData);

                    $('button', form)
                        .prop('disabled', true);
                    $('.message_holder', form)
                        .html('')
                        .hide()
                        .removeClass('alert-success')
                        .removeClass('alert-danger');

                    $.ajax({
                        url: $(form)
                            .attr('action'),
                        type: 'POST',
                        data: new FormData(form),
                        contentType: false,
                        processData: false,
                        success(response) {
                            // Handle success response
                            // console.log(response);
                            // console.log(response.message);

                            $('.message_holder', form)
                                .html(response.message)
                                .addClass('alert-success')
                                .show();

                            // Reset the form
                            form.reset();
                            $('button', form)
                                .prop('disabled', false);
                        },
                        error(xhr) {
                            $('button', form)
                                .prop('disabled', false);
                            if (xhr.status === 422) {
                                // If there are validation errors, display them
                                const { errors } = xhr.responseJSON;
                                $.each(errors, (key, value) => {
                                    if ($(`#${key}-error`).length) {
                                        $(`#${key}-error`)
                                            .html(value[0])
                                            .show();
                                    } else {
                                        $(`[name="${key}"]`)
                                            .after(`<label id="${key}-error" for="${key}" class="text-danger">${value[0]}</label>`);
                                    }
                                });
                            } else {
                                // Handle other errors
                                $('.message_holder', form)
                                    .html('An error occurred while processing your request. Please try after sometime.')
                                    .addClass('alert-danger')
                                    .show();
                            }
                        },
                    });

                    return false;
                },
            });

        /* Handle Job Application */
        $('#frmJobApplication')
            .validate({
                errorClass: 'text-danger',
                submitHandler(form) {
                    // Serialize the form data
                    // const formData = new FormData(form);
                    // console.log(formData);

                    $('button', form)
                        .prop('disabled', true);
                    $('.message_holder', form)
                        .html('')
                        .hide()
                        .removeClass('alert-success')
                        .removeClass('alert-danger');

                    $.ajax({
                        url: $(form)
                            .attr('action'),
                        type: 'POST',
                        data: new FormData(form),
                        contentType: false,
                        processData: false,
                        success(response) {
                            // Handle success response
                            // console.log(response);
                            // console.log(response.message);

                            $('.message_holder', form)
                                .html(response.message)
                                .addClass('alert-success')
                                .show();

                            // Reset the form
                            form.reset();
                            $('button', form)
                                .prop('disabled', false);
                        },
                        error(xhr) {
                            $('button', form)
                                .prop('disabled', false);
                            if (xhr.status === 422) {
                                // If there are validation errors, display them
                                const { errors } = xhr.responseJSON;
                                $.each(errors, (key, value) => {
                                    if ($(`#${key}-error`).length) {
                                        $(`#${key}-error`)
                                            .html(value[0])
                                            .show();
                                    } else {
                                        $(`[name="${key}"]`)
                                            .after(`<label id="${key}-error" for="${key}" class="text-danger">${value[0]}</label>`);
                                    }
                                });
                            } else {
                                // Handle other errors
                                $('.message_holder', form)
                                    .html('An error occurred while processing your request. Please try after sometime.')
                                    .addClass('alert-danger')
                                    .show();
                            }
                        },
                    });

                    return false;
                },
            });
    });

(function () {
    // Navbar JS
    try {
        const nav = document.querySelector('.navbar'),
            navTop = nav.offsetTop;

        function fixedNav() {
            if (window.scrollY >= navTop) {
                nav.classList.add('sticky');
            } else {
                nav.classList.remove('sticky');
            }
        }

        window.addEventListener('scroll', fixedNav);
    } catch (err) {
    }

    // Header Sticky Js
    window.addEventListener('scroll', (event) => {
        const height = 150,
            { scrollTop } = event.target.scrollingElement;
        document.querySelector('#navbar')
            .classList
            .toggle('sticky', scrollTop >= height);
    });

    // Select the button element Js
    const scrollTopBtn = document.getElementById('scrollTopBtn');

    // Show the button when the user scrolls down Js
    window.onscroll = function () {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            scrollTopBtn.style.display = 'block';
        } else {
            scrollTopBtn.style.display = 'none';
        }
    };

    // Add a click event listener to the button Js
    scrollTopBtn.addEventListener('click', () => {
        // Scroll to the top of the document
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    });

    // Preloader Js
    window.onload = function () {
        // Preloader
        const getPreloaderId = document.getElementById('preloader');
        if (getPreloaderId) {
            getPreloaderId.style.display = 'none';
        }
    };
}());

// Video Popup
function openPopup() {
    document.querySelector('.popup')
        .classList
        .add('popup-show');
}

function closePopup() {
    document.querySelector('.popup')
        .classList
        .remove('popup-show');
}

// Add the "active" class to the first element when the page loads
// window.addEventListener("load", function() {
//     var element = document.getElementById("my-element-1");
//     element.classList.add("active");
// });

// Remove the "active" class from the current element and add it to the new element when hovered over
const elements = document.querySelectorAll('[id^=\'my-element\']');
elements.forEach((element) => {
    element.addEventListener('mouseover', () => {
        elements.forEach((el) => {
            el.classList.remove('active');
        });
        element.classList.add('active');
    });
});
